import React from "react"

export default class Schedule extends React.Component {
  render() {
    return (
      <div className="main">
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="bottom-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <div className="schedule-item">
                      <h2>Welcome</h2>
                      <h2>5:30PM</h2>
                    </div>
                    <div
                      className="schedule-item arrow"
                      style={{ marginLeft: "5px" }}
                    >
                      &#x2192;
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <div className="schedule-item">
                    <h2>Ceremony</h2>
                    <h2>6:00PM</h2>
                  </div>
                  <div className="schedule-item arrow">&#x21A9;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="bottom-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <div className="schedule-item">
                      <h2>Cocktails</h2>
                      <h2>6:30PM</h2>
                    </div>
                    <div
                      className="schedule-item arrow"
                      style={{ marginLeft: "5px" }}
                    >
                      &#x2192;
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <h2>Dinner & Dancing</h2>
                  <h2>
                    7:30PM <span className="arrow-inline">&#x2192;</span>{" "}
                    11:30AM
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
